import React, { useContext } from "react";
import OrderCard from "../../../components/common/OrderCard";
import { CustomerContext } from "../../../context";
import moment from "moment";
import { useAddressFormat } from "../../../hooks";
import _ from "lodash";

const Orders = () => {
  const { customer } = useContext(CustomerContext);
  const addressFormat = useAddressFormat();

  return (
    <>
      <div className="">
        <h4 className="font-weight-bold mt-0 mb-4">Past Orders</h4>
        {customer.orders &&
          customer.orders.map((v, i) => {
            return (
              <React.Fragment key={i}>
                <OrderCard
                  image={v.lineitems?.[0].item?.photo?.url}
                  imageAlt={v.lineitems?.[0].item?.photo?.alternativeText || ""}
                  orderNumber={`${v.displayOrderId}`}
                  orderDate={`${moment(v.createAt).format(
                    "MMMM Do YYYY, h:mm:ss A"
                  )}`}
                  deliveredDate={
                    v.type === "delivery" && v.completedAt
                      ? moment(v.completedAt).format("MMMM Do YYYY, h:mm A")
                      : ""
                  }
                  orderTitle={v.lineitems?.[0].title || ""}
                  address={
                    v.type === "delivery"
                      ? addressFormat(v.shippingAddress)
                      : addressFormat(v.billingAddress)
                  }
                  orderProducts={_.join(
                    v.lineitems?.slice(0, 3).map((l) => l.title),
                    " - "
                  )}
                  orderTotal={`$${v.total}`}
                  helpLink="#"
                  detailLink={`/orders/${v.displayOrderId}?token=${v.token}`}
                />
              </React.Fragment>
            );
          })}
      </div>
    </>
  );
};
export default Orders;
