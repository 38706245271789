import React, { useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import AddAddressModal from "../../../components/modals/AddAddressModal";
import DeleteAddressModal from "../../../components/modals/DeleteAddressModal";
import AddressCard from "../../../components/common/AddressCard";
import { CustomerContext } from "../../../context";
import { useAddressFormat } from "../../../hooks";

const Addresses = () => {
  const { customer } = useContext(CustomerContext);
  const [state, setState] = useState({
    showDeleteModal: false,
    showAddressModal: false,
  });

  const addressFormat = useAddressFormat();

  const hideDeleteModal = () => setState({ ...state, showDeleteModal: false });
  const hideAddressModal = () =>
    setState({ ...state, showAddressModal: false });

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h4 className="font-weight-bold mt-0 mb-3">Manage Addresses</h4>
        </div>
      </div>
      {customer.addresses &&
        customer.addresses.map((a, i) => {
          return (
            <React.Fragment key={i}>
              <AddAddressModal
                show={state.showAddressModal}
                onHide={hideAddressModal}
              />
              <DeleteAddressModal
                show={state.showDeleteModal}
                onHide={hideDeleteModal}
              />
              <Row>
                <Col md={6}>
                  <AddressCard
                    boxClass="border shadow"
                    title={a.description}
                    icoIcon="location-pin"
                    iconclassName="icofont-3x"
                    address={addressFormat(a)}
                    onEditClick={() =>
                      setState({ ...state, showAddressModal: true })
                    }
                    onDeleteClick={() =>
                      setState({ ...state, showDeleteModal: true })
                    }
                  />
                </Col>
              </Row>
            </React.Fragment>
          );
        })}
    </>
  );
};
export default Addresses;
