import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import Layout from "../../../components/Layout";
import LeftMenu from "../LeftMenu";

const MyAccountLayout = ({
  seo,
  children,
  location,
  hasMultipleShareLocations,
}) => {
  return (
    <Layout
      seo={seo}
      showCart
      showOrderType
      hasMultipleShareLocations={hasMultipleShareLocations}
    >
      <section className="section pt-4 pb-4 osahan-account-page">
        <Container>
          <Row>
            <Col md={3}>
              <LeftMenu location={location} />
            </Col>
            <Col md={9}>{children}</Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default MyAccountLayout;
